// To fetch menu details
export const getMainMenuData = async () => {
  const url = process.env.REACT_APP_CHANNEL_DATA_JSON;
  const jsonCall = await fetch(url);
  const response = await jsonCall.json();
  return response;
};

// To get menu categories
export const getMenuDetails = async (id) => {
  const url = `${process.env.REACT_APP_CHANNEL_DATA_JSON}/tab/${id}`;
  const jsonCall = await fetch(url);
  const response = await jsonCall.json();
  return response;
};

// To get video based on menu and category selection
export const getMenuVideoDetails = async (id, catId, page, perPage) => {
  const url = `${process.env.REACT_APP_CHANNEL_DATA_JSON}/tab/${id}/${catId}/videos?page=${page}&per_page=${perPage}`;
  const jsonCall = await fetch(url);
  const response = await jsonCall.json();
  return response;
};

// Search data
export const searchData = async (query) => {
  const url = `${
    process.env.REACT_APP_CHANNEL_DATA_JSON
  }/search?search=${encodeURIComponent(query)}`;
  const jsonCall = await fetch(url);
  const response = await jsonCall.json();
  return response;
};

// Get Vast Url
export const getVastUrl = async (params) => {
  const url = process.env.REACT_APP_VAST_BASE_URL;
  const jsonCall = await fetch(`${url}?${params}`);
  const response = await jsonCall.json();
  return response;
};
