import React from 'react';
import EmptyLayout from '../layout/Empty';
import splashScreenImg from '../assets/images/splash_screen.png';

const SplashScreen = () => (
  <EmptyLayout>
    <div className="splashscreen">
      <img src={splashScreenImg} alt="Splashscreen" />
    </div>
  </EmptyLayout>
);

export default SplashScreen;
