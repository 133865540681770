import videojs from 'video.js';

const Component = videojs.getComponent('Component');

import './AdTimeline.js';

export default class AdTimelineContainer extends Component {

	createEl() {
		return super.createEl('div', {
	      className: 'vjs-ads-timeline-container'
	    });
	}
}

AdTimelineContainer.prototype.options_ = {
  children: [
    'adTimeline'
  ]
};

Component.registerComponent('adTimelineContainer', AdTimelineContainer);
