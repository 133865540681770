import React from 'react';
import PropTypes from 'prop-types';
import TopMenu from '../components/common/TopMenu';

const Main = ({
  menuData,
  activePage,
  handlePageChange,
  children,
  activePageLayoutType,
}) => (
  <div className="app-container">
    <TopMenu
      menuData={menuData}
      activePage={activePage}
      handlePageChange={handlePageChange}
      activePageLayoutType={activePageLayoutType}
    />

    {children}
  </div>
);

Main.propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  activePage: PropTypes.string.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  activePageLayoutType: PropTypes.string.isRequired,
};

export default Main;
